<template>
    <div class="ui-layout-container ui-width-100 workflow-list__wrap">
        <div class="workflow-list">
            <div class="workflow-list__header">
                <div class="workflow-list__navbar">
                    <el-menu :default-active="type" class="el-menu-bm" mode="horizontal" @select="handleSelect">
                        <el-menu-item index="all">所有业务</el-menu-item>
                        <el-menu-item index="selfStart">我发起的</el-menu-item>
                        <el-menu-item index="pending">待我处理 </el-menu-item>
                        <el-menu-item index="processed">已处理的</el-menu-item>
                    </el-menu>
                </div>
                <el-row :gutter="24" class="workflow-list__nav-form" style="margin: 6px 0px 0px 0px">
                    <el-col :span="4">
                        <div class="">
                            <el-input placeholder="请输入名称" v-model="dataForm.extraProp.name" clearable></el-input>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="">
                            <el-input
                                v-model="dataForm.extraProp.code"
                                placeholder="请输入工作单号"
                                clearable
                            ></el-input>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div>
                            <el-button @click="search" type="primary" icon="el-icon-search" style="border-radius: 13px">
                                搜索
                            </el-button>
                            <el-button
                                @click="refresh"
                                type="primary"
                                icon="el-icon-refresh"
                                style="border-radius: 13px"
                                >重置
                            </el-button>
                            <el-button
                                v-show="isAddButton"
                                @click="createFlow"
                                type="primary"
                                icon="el-icon-plus"
                                style="border-radius: 13px"
                                >新建
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="workflow-list__content">
                <!-- 自定义卡片示例1 - 业务管理 -->
                <div>
                    <template>
                        <el-table
                            ref="table"
                            :data="dataList"
                            border
                            style="width: 100%; height: 657px; overflow-y: auto !important"
                        >
                            <el-table-column
                                align="center"
                                fixed
                                prop="data.code"
                                :show-overflow-tooltip="true"
                                label="编号"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                fixed
                                prop="data.name"
                                :show-overflow-tooltip="true"
                                label="名称"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                :formatter="nodeNameValue"
                                prop="nodeName"
                                :show-overflow-tooltip="true"
                                label="状态"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="data.createTime"
                                :show-overflow-tooltip="true"
                                label="发起时间"
                            >
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="last.startTime"
                                :show-overflow-tooltip="true"
                                label="上一步操作时间"
                            >
                            </el-table-column>
                            <el-table-column align="center" fixed="right" :show-overflow-tooltip="true" label="操作">
                                <template slot-scope="scope">
                                    <div v-if="type != 'pending'">
                                        <el-button
                                            type="info"
                                            size="small"
                                            @click="openInfo(scope.row)"
                                            class="workflow-list__action-btn"
                                            >详情
                                        </el-button>
                                    </div>
                                    <div v-else-if="type == 'pending'">
                                        <el-button
                                            type="info"
                                            size="small"
                                            @click="handelFlow(scope.row)"
                                            class="workflow-list__action-btn"
                                            >处理
                                        </el-button>
                                    </div>
                                    <div v-show="deletebutton(scope.row)">
                                        <el-button
                                            type="info"
                                            size="small"
                                            class="workflow-list__action-btn"
                                            @click="deleteForm(scope.row)"
                                            >删除
                                        </el-button>
                                    </div>
                                    <div v-show="isAddButton">
                                        <el-button
                                            type="info"
                                            size="small"
                                            class="workflow-list__action-btn"
                                            @click="dataExport(scope.row)"
                                            >导出
                                        </el-button>
                                    </div>
                                    <!--<div v-if="ratifyCancellationShow(scope.row)">
                                <el-button class="workflow-list__action-btn" type="info" size="small" @click ="ratifyCancellation(scope.row)">作废</el-button>
                                </div>-->
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </div>
            </div>
            <div class="workflow-list__foot">
                <el-row :gutter="24">
                    <el-col :span="8" :offset="8">
                        <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="pageData.pageNumber"
                            :page-size="pageData.pageSize"
                            background
                            layout="total, prev, pager, next, jumper"
                            :total="total"
                        ></el-pagination>
                    </el-col>
                </el-row>
            </div>
            <handler ref="handler" @closeForm="closeForm"></handler>
        </div>
    </div>
</template>

<script>
import handler from './handler.vue';
import * as dayjs from 'dayjs';
import {fileDownload} from '@/utils';

export default {
    name: 'urgentRepairs',
    data() {
        return {
            code: 'elec_urgentRepairs_ticket',
            instanceList: [],
            dataList: [],
            type: 'pending',
            total: 0,
            pageData: {
                pageNumber: 1,
                pageSize: 5,
            },
            dataForm: {
                definitionCode: [],
                extraProp: {
                    code: '',
                },
            },
        };
    },
    components: {
        handler,
    },
    activated() {
        this.$refs.table.doLayout();
    },
    created() {
        this.search(this.type);
    },
    mounted() {
        this.$root.$on('urgentRepairsRefresh', this.refresh);
    },
    methods: {
        search() {
            this.getData(this.type);
        },
        createFlow() {
            let item = {
                code: this.code,
            };
            this.$nextTick(() => {
                let guid = this.guid();
                sessionStorage.setItem(guid, JSON.stringify(item));
                this.$router.push({
                    path: '/twoTickets/urgentRepairs/handler',
                    query: {
                        id: guid,
                        handleType: 'create',
                    },
                });
            });
        },
        handelFlow(row) {
            this.$nextTick(() => {
                let guid = this.guid();
                sessionStorage.setItem(guid, JSON.stringify(row));
                this.$router.push({
                    path: '/twoTickets/urgentRepairs/handler',
                    query: {
                        id: guid,
                        handleType: 'handle',
                    },
                });
            });
        },
        openInfo(row) {
            row.isShowAll = true;
            this.$nextTick(() => {
                let guid = this.guid();
                sessionStorage.setItem(guid, JSON.stringify(row));
                this.$router.push({
                    path: '/twoTickets/urgentRepairs/handler',
                    query: {
                        id: guid,
                        handleType: 'details',
                    },
                });
            });
        },
        handleSelect(key) {
            this.type = key;
            this.getData(key);
        },
        handleCurrentChange(val) {
            this.pageData.pageNumber = val;
            this.getData(this.type);
        },
        closeForm() {
            //恢复初始状态
            this.getData(this.type);
        },
        getData(type) {
            this.dataList = [];
            this.dataForm.type = type;
            this.initDefinitionCode();
            let page = {
                ...this.pageData,
                ...this.dataForm,
            };
            this.$client.workflowInstanceQuery(page).then((res) => {
                if (res.code === 0) {
                    let instanceList = res.data.items;
                    let recordList = [];
                    //查询实例
                    this.total = res.data.totalItemCount;
                    if (instanceList && instanceList.length) {
                        let ids = [];
                        instanceList.forEach((item) => {
                            //当前node
                            let curNodeId = item.curNode ? item.curNode.id : '';
                            let records = item.records;
                            if (records && records.length) {
                                for (let i = records.length - 1; i >= 0; i--) {
                                    if (records[i].node.id === curNodeId) {
                                        //获取当前环节数据id
                                        ids.push(records[i].id);
                                        item.recordId = records[i].id;
                                        break;
                                    }
                                }
                            }
                        });
                        if (ids && ids.length) {
                            let param = {
                                ids,
                                pageSize: ids.length,
                            };
                            this.$client.workflowInstanceQueryRecords(param).then((r) => {
                                if (r.code === 0) {
                                    recordList = r.data.items;
                                    this.setDataList(instanceList, recordList);
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error',
                                    });
                                }
                            });
                        }
                    }
                }
            });
        },
        //设置dataList
        setDataList(instanceList, recordList) {
            instanceList.forEach((item) => {
                //item.records = item.records.sort((a, b) => b.endTime > a.endTime ? 1 : -1);
                if (item.records && item.rejectNodes) {
                    item.records.forEach((record) => {
                        item.rejectNodes.forEach((recject) => {
                            if (record.node.id === recject.id) {
                                recject.handler = record.handler;
                            }
                        });
                    });
                }
            });
            if (!instanceList && !instanceList.length) {
                return;
            }
            let map = new Map();
            if (recordList && recordList.length) {
                recordList.forEach((item) => {
                    map.set(item.id, item.data);
                });
            }
            //设置处理人
            instanceList.forEach((item) => {
                (item.rejectNodes || []).forEach((reject) => {
                    item.records.forEach((record) => {
                        if (record.node.id === reject.id) {
                            reject.handler = record.handler;
                        }
                    });
                });
            });
            instanceList.forEach((item) => {
                let row = {
                    id: item.id, //实例id
                    instanceId: item.id, //实例id
                    definitionCode: item.definitionCode, //流程编码
                    nodeCode: item.nextNode ? item.nextNode.code : '', //下一环节code
                    curNodeCode: item.nextNode ? item.nextNode.code : '', //当前流程编码
                    nodeName: item.nextNode ? item.nextNode.name : '', //当前流程名称
                    user: this.dataForm.user,
                    //roles: this.dataForm.userRole,
                    data: map.get(item.recordId) ? map.get(item.recordId) : {code: ''},
                    last: item.records[item.records.length - 1],
                    rejectNodes: item.rejectNodes,
                };
                row.last.startTime = dayjs.utc(row.last.startTime).add(8, 'hours').format('YYYY-MM-DD HH:mm');
                if (row.data) {
                    this.dataList.push(row);
                }
            });
            // debugger;
        },
        initDefinitionCode() {
            this.dataForm.definitionCode = [this.code];
        },
        refresh() {
            this.search();
        },
        guid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = (Math.random() * 16) | 0,
                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
        deletebutton(row) {
            // debugger;
            if (this.type === 'selfStart' && row) {
                let createUser = row.data.createUser;
                let curUser = this.$store.state.userInfo.username;
                if (createUser === curUser) {
                    return true;
                }
            }
            return false;
        },
        deleteForm(row) {
            this.$confirm(`确定删除选中的数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                if (this.deletebutton(row)) {
                    let ids = [row.id];
                    this.$client.workflowDelete(ids).then((res) => {
                        if (res.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 2000,
                            });
                        }
                        this.getData(this.type);
                    });
                } else {
                    this.$message({
                        message: '你没有权限删除',
                        type: 'error',
                    });
                    return;
                }
            });
        },
        //是否有新增权限
        isAddButton() {
            let user = this.$store.state.userInfo;
            if (user && user.roleCodeList && user.roleCodeList.indexOf('KZYW') > -1) {
                return true;
            }
        },
        nodeNameValue(val) {
            if (val.nodeName === '') {
                return '已终结';
            }
            return val.nodeName;
        },
        dataExport(data) {
            fileDownload('/business/workTicketUrgentRepair/download', {method: 'POST', data: data.data});
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.grid-content {
    .el-select--mini,
    .el-input--mini {
        width: 150px;
        margin-right: 10px;
    }
}

.def-page-foot {
    .el-pagination {
        position: absolute;
        top: calc(50% + 10px);
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.load-workflow-step {
    background-color: @bg-conent;
    height: 100%;
    overflow: hidden scroll;
    // /deep/ .el-card{
    //     background-color: @bg-conent;
    // }
    /deep/ .card-pub-notice {
        background-color: @bg-conent;
        margin-top: 10px;

        .card-pub-notice {
            background-color: @bg-light;
            margin-top: 10px;

            .card-pub-notice {
                background-color: @bg-conent;
                margin-top: 10px;
            }
        }
    }

    /deep/ .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    /deep/ .el-card__header {
        padding-left: 10px;
    }
}

.workflow-list {
    position: relative;
    height: calc(100% - 10px);

    &__action-btn {
        float: right;

        & + & {
            margin-left: 10px;
        }
    }

    &__notice-count {
        background-color: #ff7260;
        color: #fff;
        border-radius: 10px;
        font-size: 12px;
        padding: 0 5px;
        position: relative;
        top: -8px;
    }

    &__card-head-border {
        border-left: 3px solid @bg-btn-light;
        padding-left: 10px;
    }

    &__card-head-type {
        margin-left: 30px;
    }

    &__item-card {
        background-color: @bg-dark;

        & + & {
            margin-top: 20px;
        }
    }

    &__nav-form {
        padding: 10px;
        padding-bottom: 20px;
    }

    &__wrap {
        background-color: @bg-light;
        height: 100%;
        width: 100%;
    }

    &__content {
        height: calc(100% - 140px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }

    &__foot {
        height: 35px;
    }

    &__header {
        height: 105px;
        background-color: @bg-dark;
        border-bottom: 1px solid @bg-border;
    }

    &__navbar {
        border-bottom: 1px solid @bg-border;
        padding-top: 10px;

        /deep/ .el-menu {
            background-color: transparent;
        }

        /deep/ .el-menu.el-menu--horizontal {
            height: 30px;
            border: none;
            margin-right: 0;
            margin-bottom: 0;
        }

        /deep/ .el-menu--horizontal > .el-menu-item {
            height: 35px;
            line-height: 35px;
        }

        /deep/ .el-header {
            padding: 0;
        }

        /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
        /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
        /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
            background-color: lighten(@bg-dark, 22%);
        }

        /deep/ .el-menu--horizontal > .el-menu-item.is-active {
            border-color: #ededfd;
        }
    }

    /deep/ .el-card__header {
        padding: 15px 20px;
    }

    /deep/ .el-card__body {
        padding: 15px 20px;
        font-size: 14px;
    }
}
</style>